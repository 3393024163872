import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import FormSection from "./Form/Form";
import ContactCard from "./ContactCard/ContactCard";

const Contact = (props) => {
    const myRef = useRef(null)
    const executeScroll = () => {
        myRef.current.scrollIntoView({
            behavior: "smooth"
        })
    }
    return (
        <React.Fragment>
            <div className="contact-form">
                <Container>
                    <Row>
                        <Col lg="7" xl="7">
                            <div ref={myRef} id="scroll_view"></div>
                            <FormSection />
                        </Col>
                        <Col lg="5" xl="5" className="ml-lg-auto contact-details-card">
                            <ContactCard executeScroll={executeScroll} contact={props.contact} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
};

export default Contact;